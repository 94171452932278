<template>
  <div>
    <!--Modal add sublocation-->
    <div id="modalAddSubLocation" class="modal fade" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button class="close" data-dismiss="modal">&times;</button>
            <h4 id="modalAddSubLocationTitle" class="modal-title">{{$t("project.AddSubLocationTitle")}}</h4>
          </div>
          <div class="modal-body">
            <p>{{$t("project.AddSubLocationHelp")}}</p>
            <input id="txtname" v-model="name" type="text" class="form-control" @keypress="checkSubmit">
            <p v-if="!valid" class="error">{{errorMessage}}</p>
          </div>
          <div class="modal-footer">
            <button id="btnAddSubLocation" class="btn" v-bind:class="{ disabled: !enableAddButton }" @click="add">{{$t("project.CreateButton")}}</button>
          </div>
        </div>
      </div>
    </div>

    <!--Modal delete sublocation-->
    <div id="modalDeleteSubLocation" class="modal fade" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 id="modalDeleteSubLocationTitle" class="modal-title">{{$t("project.DeleteSubLocationTitle")}}</h4>
          </div>
          <div class="modal-body">
            <p>{{$t("project.DeleteSubLocationHelp")}}</p>
          </div>
          <div class="modal-footer">
            <button id="btnDeleteSubLocation" class="btn" @click="deleteSubLocation">{{$t("project.DeleteButton")}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  name: 'slModal',
  props: ['functions', 'option'],

  data () {
    return {
      errorMessage: '',
      name: '',
      valid: false
    }
  },

  computed: {
    enableAddButton () {
      return this.valid && !this.$parent.loading
    }
  },

  watch: {
    'option.suggestedName': {
      handler: function () {
        this.name = this.option.suggestedName
      }
    },
    'name': {
      handler: function () {
        this.validate()
      }
    }
  },

  mounted () {
    this.validate()
  },

  methods: {
    checkSubmit (evt) {
      if (evt.key === 'Enter' && this.valid) {
        this.add()
      }
    },
    validate () {
      let SlNameField = {
        key: 'SlName',
        maxLength: 50,
        requiredBySystem: true,
        requiredByUser: true
      }
      this.$validateEditor(SlNameField, this.name, {
        SlGuid: '' /* empty since it cant ever match with itself */
      })
        .then(() => {
          this.valid = true
        }).catch(reason => {
        // extract reason and set valid to false
          if (typeof reason.message === 'undefined') { console.error(reason) }
          this.valid = false
          this.errorMessage = reason.message
        })
    },
    add () {
      return this.functions.add({ name: this.name })
    },
    resetDefault () {
      this.errorMessage = ''
      this.name = ''
    },
    deleteSubLocation () {
      return this.functions.delete()
    }
  }
}
</script>
